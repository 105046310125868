import React from 'react'
import { useRoutes } from 'react-router-dom'
import CoreLayout from '../layouts/CoreLayout'
import { NotFound } from './Statics'

import AuthRoutes from './Auth'
import Home from './Home'
import ArticlesRoutes from './Articles'
import ArticleReportsRoutes from './ArticleReports'
import CommentsRoutes from './Comments'
import TagsRoutes from './Tags'
import UsersRoutes from './Users'
import QuestionsRoutes from './Questions'
import AnswersRoutes from './Answers'
import GroupsRoutes from './Groups'

const Routes = () => {
  const routesConfig = [
    AuthRoutes(),
    {
      element: <CoreLayout />,
      children: [
        Home(),
        ArticlesRoutes(),
        ArticleReportsRoutes(),
        CommentsRoutes(),
        TagsRoutes(),
        UsersRoutes(),
        QuestionsRoutes(),
        AnswersRoutes(),
        GroupsRoutes(),
        { path: '*', element: <NotFound /> },
      ],
    },
  ]

  return useRoutes(routesConfig)
}

export default Routes
